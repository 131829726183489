<template>
  <FocusTrap>
    <div class="card">
    <div class="card-header header-elements-inline">
      <h5 class="card-title">Extrusion Report (Direct Warp)</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="modal"  @click="showFilterWindow"></a>
          <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>


<!--    <table class="table table-togglable table-hover footable-loaded footable tablet breakpoint">-->
    <table id="table" class="table table-bordered table-condensed table-columned table-hover">
        <thead>
        <tr>
          <th class="footable-visible footable-first-column text-center" style="width: 107px; padding: 0px;">Date</th>
          <th data-hide="phone" class="footable-visible">Shift</th>
          <th class="footable-visible text-right">Production In Kgs</th>
          <th class="footable-visible">Quality</th>
          <th class="footable-visible">Color</th>
          <th class="footable-visible">Size</th>
          <th class="footable-visible text-right">No of Beams</th>
          <th class="footable-visible text-right">Lumps</th>
          <th class="footable-visible text-right">Yarn Waste</th>
          <th class="footable-visible text-right">Raw Met Excess</th>

<!--          <th class="footable-visible text-right">Raw Met Stock</th>-->
<!--          <th class="footable-visible text-right">Color Stock</th>-->
        </tr>
        </thead>
      <tbody>
        <tr v-for="det in deliveries">

          <td class="footable-visible footable-first-column" >{{det.doc_date | dateFormatter}}  </td>
<!--          <td class="footable-visible">{{det.shf_cd | shiftFormatter}}</td>-->
          <td class="footable-visible">{{det.shift}}
          <td class="footable-visible text-right"> <a href="#"> {{det.prod_kgs }} </a></td>
          <td class="footable-visible">{{det.quality}}</td>
          <td class="footable-visible">{{det.color}}</td>
          <td class="footable-visible">{{det.yarn_size}}</td>
          <td class="footable-visible text-right">{{det.qty}}</td>
          <td class="footable-visible text-right">{{det.lumps_wgt}}</td>
          <td class="footable-visible text-right">{{det.yarn_waste_wgt}}</td>
          <td class="footable-visible text-right">{{det.raw_mat_excess}}</td>

<!--          <td class="footable-visible text-right">{{det.rawstock| weightFormat}}</td>-->
<!--          <td class="footable-visible text-right">{{det.colstock| weightFormat}}</td>-->
        </tr>
      </tbody>
      <tfoot>
        <tr style="background-color: whitesmoke;" class="font-weight-semibold">
          <td ></td>
          <td >Total</td>
          <td class="text-right"> {{prodTotal | weightFormat}} </td>
          <td ></td>
          <td ></td>
          <td ></td>
          <td class="text-right">  {{beamsTotal }}</td>
          <td class="text-right">  {{lumpsTotal | weightFormat}}</td>
          <td class="text-right">  {{yarnwasteTotal | weightFormat}}</td>
          <td class="text-right">  {{rawexcessTotal | weightFormat }}</td>
<!--          <td class="text-right">  {{rawmetTotal | weightFormat}}</td>-->
<!--          <td class="text-right">  {{colorTotal | weightFormat}}</td>-->
        </tr>
      </tfoot>
    </table>

    <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="42%" height="420" >

        <form>
        <div class="card">
          <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
            <h3 class="card-title">Filter</h3>
            <div class="header-elements">
              <div class="list-icons">
                <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
              </div>
            </div>
          </div>

          <div class="card-body">

              <p/>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">From:</label>
                <div class="col-md-4">
                  <input type="date" class="form-control" ref="from_date"  v-model="entry_from" :max="upto_date" />
                </div>

                <label class="col-md-2 col-form-label">Upto</label>
                <div class="col-md-4">
                  <input type="date" class="form-control" ref="upto_date"  v-model="entry_upto" :min="from_date" />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Quality:</label>
                <div class="col-md-10">
                  <select id="cmbQuality" class="form-control" v-model="qua" >
                    <option value="0" selected>All</option>
                    <option v-for="ledger in qualities" v-bind:value="ledger.code">
                      {{ ledger.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Color:</label>
                <div class="col-md-10">
                  <select class="form-control" v-model="col" >
                    <option value="0" selected>All</option>
                    <option v-for="ledger in colors" v-bind:value="ledger.code">
                      {{ ledger.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Extruder:</label>
                <div class="col-md-10">
                  <select class="form-control" v-model="mac" >
                    <option value="0" selected>All</option>
                    <option v-for="ledger in machines" v-bind:value="ledger.code">
                      {{ ledger.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Employee:</label>
                <div class="col-md-10">
                  <select class="form-control" v-model="emp" >
                    <option value="0" selected>All</option>
                    <option v-for="ledger in emps" v-bind:value="ledger.code">
                      {{ ledger.name }}
                    </option>
                  </select>
                </div>
              </div>


              <div class="text-right">
                <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
              </div>

          </div>
        </div>
      </form>

    </vmodal>


  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'ExtrusionReport',
    store,
    components: {

    },
    data () {
      return {
        mytable: {},
        deliveries: [],
        qualities: [],
        colors:[],
        machines: [],
        emps: [],
        entry_from: moment().format('YYYY-MM-DD'),
        entry_upto: moment().format('YYYY-MM-DD'),
        upto_date:moment().format('YYYY-MM-DD'),
        from_date:moment().format('YYYY-MM-DD'),
        col:0,
        qua:0,
        mac:0,
        emp:0,
        prodTotal:0,
        beamsTotal:0,
        lumpsTotal:0,
        yarnwasteTotal:0,
        rawexcessTotal:0,
        rawmetTotal:0,
        colorTotal:0,
      }
    },
    mounted () {
      const self = this;

      this.$data.mytable = $('.table');

      this.loadQualities();
      this.loadColors();
      this.loadMachines();
      this.loadEmployees();
      this.showFilterWindow();

      $('filter-window').onShow(function (){
        self.refs.from_date.focus();
      });

    },
    filters:{
      weightFormat (value) {
        if( value !== 0)
          return value.toFixed(3);
        else
          return '';
      },
      dateFormatter(val){
        try {
          return val.slice(0, 10).split('-').reverse().join('-');
        } catch (ex) {

        }
        return '';
      },
      shiftFormatter(val){
        try {
          if(val == 2){
            return 'Day';
          }else if(val == 3){
            return 'Night';
          }
          return val;
        } catch (ex) {

        }
        return '';
      }
    },
    methods:{
      showFilterWindow(){
        this.$modal.show('filter-window');
      },
      closeWindow(){
        this.$router.push('/');
      },
      push_total_slot(det){
        const self = this;

        self.prodTotal +=  parseFloat(det.prod_wgt);
        self.beamsTotal += det.qty;
        self.lumpsTotal += det.lumps_wgt;
        self.yarnwasteTotal += det.yarn_waste_wgt;
        self.rawexcessTotal += det.raw_mat_excess;
        self.rawmetTotal= 0;
        self.colorTotal= 0;
      },
      loadData() {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:'cors',
          headers: userService.authHeader()
        };

        $(self.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$modal.hide('filter-window');
        self.mytable.bootstrapTable('load',[]);
        self.mytable.bootstrapTable('showLoading');

        self.deliveries = [];
        self.prodTotal = 0;
        self.beamsTotal= 0;
        self.lumpsTotal= 0;
        self.yarnwasteTotal= 0;
        self.rawexcessTotal= 0;
        self.rawmetTotal= 0;
        self.colorTotal= 0;

        // alert(`${process.env.VUE_APP_ROOT_API}api/report/extrusion/direct_warp?col=${self.col}&qua=${self.qua}&from=${self.entry_from}&upto=${self.entry_upto}`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/report/extrusion/direct_warp?col=${self.col}&qua=${self.qua}&from=${self.entry_from}&upto=${self.entry_upto}`,requestOptions).then(userService.handleResponse).then(function (resp) {


          if (resp.ok) {

            if(Array.isArray( resp.data )){
              self.deliveries = resp.data;

              self.deliveries.forEach( (det) => {

                // console.log(JSON.stringify(det));

                self.prodTotal +=  parseFloat(det.prod_kgs);
                self.beamsTotal += parseFloat(det.qty);
                self.lumpsTotal += parseFloat(det.lumps_wgt);
                self.yarnwasteTotal += parseFloat(det.yarn_waste_wgt);
                self.rawexcessTotal += parseFloat(det.raw_mat_excess);
                self.rawmetTotal= 0;
                self.colorTotal= 0;

              });

            }

          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }

        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        }).finally(function () {
          self.mytable.bootstrapTable('hideLoading');
          $(self.mytable).unblock();
        });

      },
      loadQualities() {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:'cors',
          headers: userService.authHeader()
        };


        self.qualities = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/qualities`,requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok){
            if(_.isArray(resp.data)){
              self.qualities = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      loadColors() {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:'cors',
          headers: userService.authHeader()
        };

        self.colors = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/colorcharts`,requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok){
            if(_.isArray(resp.data)) {
              self.colors = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      loadMachines() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        self.$data.machines = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/machines/type/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            self.$data.machines = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      loadEmployees() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        self.$data.emps = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/employees`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if( resp.data != null){
              self.$data.emps = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      },
    }
  }
</script>

<style scoped>

</style>
